.topbar-menu-wrapper {
    border-right: 1px solid rgba(255,255,255,.2);
    border-left: 1px solid rgba(255,255,255,.2);
}

.topbar-menu {
    margin-bottom: 0;
}

.topbar-menu li {
    display: inline-block;
}

.topbar-menu li a.list-item {
    font-size: .9em;
}

@media (max-width: 767px) {
    .topbar-menu-wrapper {
        display: none;
    }
}

.no-cookie-consent {
    min-height: 135px;
}

@media (max-width: 767px) {
    .no-cookie-consent {
        min-height: 350px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .no-cookie-consent {
        min-height: 250px;
    }
}

.embedded-header-wrapper .it-header-wrapper .navbar-collapsable .logo-hamburger {
    display: none;
}

.embedded-header-wrapper .it-brand-text {
    font-size: .9em;
    margin-left: -15px;
}

@media (max-width: 991px) {
    .embedded-header-wrapper .navbar .navbar-collapsable .navbar-nav li a.nav-link {
        color: var(--color-primary);
    }

    .embedded-header-wrapper .it-header-wrapper .navbar-collapsable .logo-hamburger {
        display: flex;
        align-items: center;
        color: var(--color-primary);
        padding: 32px 50px 16px 24px;
    }
}

@media (max-width: 1399px) {
    .navbar .navbar-collapsable .navbar-nav li.nav-item .nav-link {
        padding-left: 0;
    }

    .embedded-header-wrapper nav[aria-label="Secondaria"] {
        padding-left: 0;
    }
}

.embedded-header-wrapper .it-header-wrapper .navbar-collapsable .logo-hamburger .it-brand-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.167;
    max-width: 200px;
}

@media (min-width: 992) {
    .embedded-header-wrapper .it-header-wrapper .navbar-collapsable .logo-hamburger {
        display: none;
    }
}

.embedded-header-wrapper .navbar {
    background: var(--color-primary);
}

@media (max-width: 991px) {
    .embedded-header-wrapper .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon {
        width: 48px;
        height: 48px;
    }
    
    .embedded-header-wrapper .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a .icon image {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 992px) {
    .embedded-header-wrapper .navbar .navbar-collapsable .menu-wrapper {
        background: var(--color-primary);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    
    .embedded-header-wrapper .it-header-wrapper .navbar .navbar-collapsable .menu-wrapper .navbar-nav {
        padding: 0;
    }

    .embedded-header-wrapper .navbar .navbar-collapsable .navbar-secondary li a.nav-link {
        font-size: .85em !important;
        line-height: 1.6 !important;
    }

    .embedded-header-wrapper nav[aria-label="Secondaria"] {
        margin-left: auto;
    }
}

.embedded-header-wrapper #search-modal.show {
    display: flex !important;
}

.embedded-header-wrapper .modal-dialog.modal-lg {
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
}

.embedded-header-wrapper .search-modal .modal-title {
    color: #455a64;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.embedded-header-wrapper .search-modal .modal-title h2 {
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 1.5;
}

.embedded-header-wrapper button.search-link {
    background: none;
    border: none;
    fill: #003882;
    display: block !important;
    margin-left: auto;
}

.embedded-header-wrapper button.search-link.d-md-none {
    display: none !important;
}

@media (min-width: 576px) {
    .embedded-header-wrapper .search-modal .modal-title {
        margin-bottom: 3.89rem;
    }

    .embedded-header-wrapper .search-modal .modal-title h2 {
        font-size: 2.6666666667rem;
    }    
}

.embedded-header-wrapper .input-group .input-group-prepend~label {
    display: none;
}

.embedded-header-wrapper .form-group {
    display: flex;
    gap: 20px;
    margin-top: 6em;
}

.embedded-header-wrapper .input-group {
    border-bottom: 1px solid #5d7083;
    padding-bottom: 10px;
}

.embedded-header-wrapper .input-group>.form-control, .embedded-header-wrapper .input-group .input-group-text {
    border-bottom: none;
}

.embedded-header-wrapper .input-group>.form-control::placeholder {
    color: #b4bdc5;
    font-weight: 400;
}

.embedded-header-wrapper .btn.btn-primary {
    background: var(--color-primary);
}

.embedded-header-wrapper .search-modal .form-group input {
    color: #17324d;
}

@media (min-width: 576px) {
    .embedded-header-wrapper .search-modal .form-group input {
        font-size: 2.2222222222rem;
    }
}

@media (max-width: 500px) {
    .embedded-header-wrapper .search-modal .modal-title {
        justify-content: flex-start;
        align-items: center;
    }

    .embedded-header-wrapper .modal-dialog.modal-lg {
        margin: 0;
    }

    .embedded-header-wrapper .modal .modal-dialog .modal-content .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .embedded-header-wrapper .input-group .input-group-prepend .icon {
        width: 20px;
    }

    .embedded-header-wrapper .input-group {
        padding-bottom: 0;
    }
}

.embedded-header-wrapper .it-header-wrapper .navbar-collapsable .it-socials {
    margin-top: 30px;
    padding: 0 24px;
    color: var(--color-primary);
}

@media (min-width: 992px) {
    .embedded-header-wrapper .it-header-wrapper .navbar-collapsable .it-socials {
        display: none;
    }
}

.embedded-footer-wrapper .it-footer-main {
    padding-top: 30px;
    padding-bottom: 30px;
}

@media (min-width: 768px) {
    .embedded-footer-wrapper .footer-items-wrapper.logo-wrapper {
        display: flex;
        align-items: center;
    }
}

.embedded-footer-wrapper .it-footer-main .it-brand-wrapper a .icon {
    width: 82px;
    height: 82px;
}

.embedded-footer-wrapper .it-footer .footer-heading-title {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
}

@media (min-width: 576px) {
    .it-footer .footer-heading-title {
        font-size: .7777777778rem;
    }
}

@media (min-width: 768px) {
    .it-footer .footer-heading-title {
        margin-bottom: 0.889rem;
        padding-bottom: 0.889rem;
        border-bottom: 1px solid hsla(0,0%,100%,.5);
    }
}

.embedded-footer-wrapper .it-footer .footer-list a {
    line-height: 2;
    color: #fff;
    font-size: .8888888889rem;
}

.embedded-footer-wrapper ul {
    list-style: none;
}

.embedded-footer-wrapper .it-footer .footer-list+.footer-heading-title {
    margin-top: 2.667rem;
}

.embedded-footer-wrapper .it-footer .footer-bottom {
    margin-top: 1.667rem;
    padding-top: 1.333rem;
    padding-bottom: 2.5rem;
    border-top: 1px solid hsla(0,0%,100%,.5);
}